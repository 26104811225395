import React from "react";
import { NavLink } from "react-router-dom";
import { api } from "./const";
import { Form } from "react-bootstrap";
import { isAndroid, isIOS } from "react-device-detect";
import img from "../icons/192.png";
import ellipsis from "../icons/ellipsis.svg";
import share from "../icons/share.svg";
import "bootstrap/dist/js/bootstrap.js";
import Button from '@material-ui/core/Button';
import { browserName, fullBrowserVersion, osName } from "react-device-detect";
function Setup(props) {
  const [Location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
  });
  const [isloading, setIsloading] = React.useState(false);
  const setupApi = async () => {
    setIsloading(true);

    const userData = JSON.parse(await localStorage.getItem(props.userid));

    let sid = userData.session_id;
    let uid = userData.userid;
    const res = await fetch(`${api}/setup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
        browser_info: {
          name: browserName,
          version: fullBrowserVersion,
          device_type: osName,
          other_info: "is now",
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setIsloading(false);

      alert(data.message);
    } else if (res.status === 200) {
      setIsloading(false);
      props.setup1();
    }
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const [isChecked, setIsChecked] = React.useState(false);

  const [id, setId] = React.useState("");

  React.useEffect(() => {
    if (isAndroid) {
      setId("#android-add-to-home-screen");
    } else if (isIOS) {
      setId("#ios-add-to-home-screen");
    } else {
      setId("#android-add-to-home-screen");
    }
  }, []);

  React.useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  return (
    <div>
      <div id="appCapsule">
        <div className="section mb-5 p-2 center1">
          <h1 className="mb-3" style={{ textAlign: "center" }}>
            Setup
          </h1>
          <div className="card">
            <div className="card-body pb-1">
              <div className="form-group basic">
                <div className="input-wrapper">
                  <label
                    className="label"
                    htmlFor="text"
                    style={{ fontSize: 17 }}
                  >
                    Press the 'Add to Home Screen' button and follow the
                    instructions.
                  </label>
                </div>
                {/* //* checkbox start ............. */}
                <Form style={{ marginTop: 28 }}>
                  <Form.Group
                    className="mb-3 my-auto"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="I have completed the setup."
                      checked={isChecked}
                      onChange={handleOnChange}
                    />
                  </Form.Group>
                </Form>
                {/* //* Button start........ */}
                <div className="mt-3 d-flex justify-content-space-between">
                  <Button
                    type="button"
                    className="btn btn-lg btn-primary square btn-block"
                    data-bs-toggle="modal"
                    data-bs-target={id}
                  >
                    Add to Home Screen
                  </Button>

                  <div style={{ marginLeft: 10 }}>
                    {!isloading ? (
                      <Button
                        type="button"
                        className="btn btn-primary square btn-lg me-1"
                        disabled={!isChecked ? "disabled" : null}
                        onClick={setupApi}
                      >
                        Complete
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        disabled
                        className="btn btn-primary btn-block btn-lg square"
                      >
                        <span
                          className="spinner-border spinner-border-sm me-05"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Complete
                      </Button>
                    )}
                  </div>
                </div>
                {/* // * Button end........ */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // * android add to home start........... */}
      <div
        className="modal inset fade action-sheet ios-add-to-home"
        id="ios-add-to-home-screen"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add to Home Screen</h5>
              <NavLink to="#" className="close-button" data-bs-dismiss="modal">
                <ion-icon name="close"></ion-icon>
              </NavLink>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content text-center">
                <div className="mb-1">
                  <img src={img} alt="icon" className="imaged w64 mb-2" />
                </div>

                <div>
                  <h5 class="modal-title">
                    Tap{" "}
                    <ion-icon>
                      <img src={share} alt="icon" height="28" width="28" />
                    </ion-icon>{" "}
                    then Add to Home screen.
                  </h5>
                </div>
                <div className="mt-2">
                  <Button
                    className="btn btn-primary btn-block"
                    data-bs-dismiss="modal"
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //* ios add to home start............ */}
      <div
        className="modal inset fade action-sheet android-add-to-home"
        id="android-add-to-home-screen"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add to Home Screen</h5>
              <NavLink to="#" className="close-button" data-bs-dismiss="modal">
                <ion-icon name="close"></ion-icon>
              </NavLink>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content text-center">
                <div className="mb-1">
                  <img src={img} alt="icon" className="imaged w64 mb-2" />
                </div>

                <div>
                  <h5 class="modal-title">
                    Tap{" "}
                    <ion-icon>
                      <img src={ellipsis} alt="icon" height="24" width="24" />
                    </ion-icon>{" "}
                    then Add to Home Screen.
                  </h5>
                </div>
                <div className="mt-2">
                  <Button
                    className="btn btn-primary btn-block"
                    data-bs-dismiss="modal"
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setup;
