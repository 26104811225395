import React from "react";
import Header from "./Header";
import "../App.css";

import BottomNav from "./BottomNav";
import { useParams } from "react-router-dom";
import Setup from "./Setup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { api } from "./const";
import { browserName, fullBrowserVersion, osName } from "react-device-detect";
import StartBtnModal from "./StartBtnModal";
import NotesModal from "./NotesModal";
import SupplyModal from "./SupplyModal";
import StopModal from "./StopModal";
import Button from '@material-ui/core/Button';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Home(props) {
  const childRef = React.useRef();
  const childRef1 = React.useRef();

  const { id } = useParams();
  const [isAllList, setIsAllList] = React.useState(false);
  const [jobdata, setJobData] = React.useState({});
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  // eslint-disable-next-line
  var [startSec, setStartSec] = React.useState(0);
  const [isNextClick, setIsNextClick] = React.useState(false);
  const [Location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const nextbtnmodal = () => {
    setIsNextClick(true);
  };
  // const handelRedio = () => {
  //   setIsRedioChecked(!isRedioChecked);
  // };
  const showAllJobList = () => {
    setIsAllList(true);
    setIsNextClick(false);
    // setIsRedioChecked(false);
  };
  const showtodayJobList = () => {
    setIsAllList(false);
    setIsNextClick(false);
    // setIsRedioChecked(false);
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const modalOff = () => {
    setModalShow2(false);
  };

  const NotesModalOff = () => {
    setModalShow(false);
  };

  React.useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);
  // eslint-disable-next-line
  React.useEffect(async () => {
    const userData = JSON.parse(await localStorage.getItem(id));

    let record_id = userData.record_id;

    if (record_id !== null && record_id !== undefined) {
      setBtn(false);
      setIsChecked(true);
    } else {
      setBtn(true);
      setIsChecked(false);
    }
  });

  const clicked = () => {
    var seconds = new Date().getSeconds();

    setStartSec(seconds);
    handleOnChange();
    setBtn(false);
  };

  // const clickedStop = () => {
  //   var seconds = new Date().getSeconds();

  //   var difference = seconds - startSec;

  //   if (difference < 0) {
  //     difference = -difference;
  //   }
  //   if (difference < 30) {
  //     setError(`You are left ${30 - difference} seconds please wait`);
  //     setOpen(true);
  //   } else {
  //     handleOnChange();
  //     setBtn(true);
  //     setStartSec(0);
  //     setError("");
  //     setOpen(true);
  //   }
  // };

  const startApi = async () => {
    setJobData({});
    setIsAllList(false);
    setIsNextClick(false);
    setModalShow2(true);

    const userData = JSON.parse(await localStorage.getItem(id));

    let sid = userData.session_id;
    let uid = userData.userid;
    const res = await fetch(`${api}/list-all-type-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
        browser_info: {
          name: browserName,
          version: fullBrowserVersion,
          device_type: osName,
          other_info: "is now",
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
    } else if (res.status === 200) {
      setJobData(data);
    }
  };


  const [isloading1, setIsloading1] = React.useState(false);

  const stopApi = async () => {
    setIsloading1(true);

    const userData = JSON.parse(await localStorage.getItem(id));

    let sid = userData.session_id;
    let uid = userData.userid;
    let rid = userData.record_id;
    const res = await fetch(`${api}/stop-job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        record_id: rid,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setIsloading1(false);
      setError(data.message);
      setOpen(true);
    } else if (res.status === 200) {
      let userData = JSON.parse(await localStorage.getItem(id));
      userData.record_id = null;
      userData.selected_Job = null;
      // userData.number_of_people=0;
      const jsonValue = JSON.stringify(userData);
      await localStorage.setItem(id, jsonValue);
      // eslint-disable-next-line
      {
        childRef.current.cleanNote();
        
      }
       // eslint-disable-next-line
      {childRef1.current.noofpersion();
      }
      // clickedStop();
      setError("");
      setOpen(true);
      setModalShow3(false);
      setIsloading1(false);
    }
  };

  const [isChecked, setIsChecked] = React.useState(false);
  // const [isRedioChecked, setIsRedioChecked] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [state, setstate] = React.useState(false);
  const [btn, setBtn] = React.useState(true);

  const setupApp = async () => {
    let userData = JSON.parse(await localStorage.getItem(id));

    let bool = {
      setup: true,
    };

    let z = Object.assign(userData, bool);
    const jsonValue = JSON.stringify(z);
    await localStorage.setItem(id, jsonValue);
    setstate(true);
  };
  const toggleComponent = async () => {
    let userData = JSON.parse(await localStorage.getItem(id));

    setstate(userData.setup);
  };

  React.useEffect(() => {
    toggleComponent();

    // eslint-disable-next-line
  }, []);

  const [supplyData, setSupplyData] = React.useState({});

  const getSupplyResult = async () => {
    setSupplyData({});
    setModalShow1(true);
    let userData = JSON.parse(await localStorage.getItem(id));
    let sid = userData.session_id;
    let uid = userData.userid;

    let jid = userData.job_id;

    const res = await fetch(`${api}/get-job-supply`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: jid,

        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
    } else if (res.status === 200) {
      setSupplyData(data);
      console.log("i am supply result", data.result);
    }
  };
  const [preNotes, setPreNotes] = React.useState("");
  const [loder, setLoder] = React.useState(false);
  const getNotes = async () => {
    setModalShow(true);
    setLoder(true);

    let userData = JSON.parse(await localStorage.getItem(id));
    let sid = userData.session_id;
    let uid = userData.userid;
    let rid = userData.record_id;

    const res = await fetch(`${api}/get-note`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        record_id: rid,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setLoder(false);
    } else if (res.status === 200) {
      setPreNotes(data.note);
      setLoder(false);
      // let userData = JSON.parse(await localStorage.getItem(id));

      // let userData1 = {
      //   usernotes: data.note,
      // };

      // let z = Object.assign(userData, userData1);
      // const jsonValue = JSON.stringify(z);
      // await localStorage.setItem(id, jsonValue);
    }
  };

  let userData = JSON.parse(localStorage.getItem(id));
  return state ? (
    <div className="App">
      <Header name="Home" />

      <div style={{ marginTop: 65 }}>
        <h2 style={{ textAlign: "center" }}>
          Logged in as {userData.userName}
        </h2>
        <h2 style={{ textAlign: "center" }}> {userData.selected_Job} </h2>
      </div>

      <div id="appCapsule" className="centered">
        <div className="centered">
          {btn ? (
            <Button
              type="button"
              className="btn btn-circle me-1"
              // onClick={clicked}

              onClick={startApi}
            >
              START
            </Button>
          ) : (
            <Button
              type="button"
              className="btn btn-circle me-1 red"
              // onClick={stopApi}
              onClick={() => setModalShow3(true)}
            >
              STOP
            </Button>
          )}
        </div>
      </div>
      <div className="two-buttons">
        <Button
          type="button"
          className={
            !isChecked ? "btn btn-circle1 disabled " : "btn btn-circle1 enabled "
          }
          onClick={getNotes}
          disabled={!isChecked ? "disabled" : null}
        >
          Notes
        </Button>
        <Button
          type="button"
          className={
            !isChecked ? "btn btn-circle1 disabled " : "btn btn-circle1 enabled"
          }
          onClick={getSupplyResult}
          disabled={!isChecked ? "disabled" : null}
        >
          Supplies
        </Button>
      </div>

      <StopModal
        show={modalShow3}
        onHide={() => setModalShow3(false)}
        stopApi={stopApi}
        isloading1={isloading1}
        userid={id}
      />

      <NotesModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        NotesModalOff={NotesModalOff}
        userid={id}
        preNotes={preNotes}
        ref={childRef}
        loder={loder}
      />
      <SupplyModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        supplyData={supplyData}
        userid={id}
      />
      <StartBtnModal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        Joblist={jobdata}
        allList={isAllList}
        showAllJobList={showAllJobList}
        showtodayJobList={showtodayJobList}
        nextbtnmodal={nextbtnmodal}
        isNextClick={isNextClick}
        clicked={clicked}
        modalOff={modalOff}
        userid={id}
        ref={childRef1}
        // isRedioChecked={isRedioChecked}
        // handelRedio={handelRedio}
      />
      <BottomNav userid={id} />
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {error !== "" ? (
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          ) : (
            <Alert onClose={handleClose} severity="success">
              The job is completed
            </Alert>
          )}
        </Snackbar>
      </div>
    </div>
  ) : (
    <Setup setup1={setupApp} userid={id} />
  );
}

export default Home;
