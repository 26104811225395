import React from "react";
import { Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
function StopModal(props) {
  let userData = JSON.parse(localStorage.getItem(props.userid));

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {userData.selected_Job}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-4">
        <h3>Are you sure you want to end this Job? </h3>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="col-3 btn btn-primary square btn-lg me-2"
          onClick={props.onHide}
        >
          No
        </Button>
        {!props.isloading1 ? (
          <Button
            type="button"
            className="col-3 btn btn-primary square btn-lg me-2"
            onClick={props.stopApi}
          >
            Yes
          </Button>
        ) : (
          <Button
            type="button"
            disabled
            className="col-3 btn btn-primary square btn-lg me-2"
          >
            {" "}
            <span
              className="spinner-border spinner-border-sm me-05"
              role="status"
              aria-hidden="true"
            ></span>
            Yes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default StopModal;
