import React, { forwardRef, useImperativeHandle } from "react";
import { api } from "./const";
import { Modal } from "react-bootstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Ripples from "react-ripples";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const StartBtnModal = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const v = props.Joblist.result;

  let title = "";
  const [selectedJob, setSelectedJob] = React.useState("");
  const [noOfPerson, setNoOfPerson] = React.useState(0);
  const [isloading, setIsloading] = React.useState(false);
  const [Location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
  });
  const [error, setError] = React.useState("");
  const [id, setId] = React.useState();
  const [record, setRecord] = React.useState(true);
  const [counters, setcounters] = React.useState({
    active: 0,
    all: 0,
  });
  if (!props.allList && !props.isNextClick) {
    title = `Today's Jobs-${counters.active}`;
  } else if (props.allList && !props.isNextClick) {
    title = `All Jobs-${counters.all}`;
  } else if (props.isNextClick) {
    title = selectedJob;
  }
  const handleChange = (e) => {
    setSelectedJob(e.target.value);

    setId(e.target.id);
    setTimeout(() => {
      props.nextbtnmodal();
    }, 200);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const startJobapi = async () => {
    setIsloading(true);

    const userData = JSON.parse(await localStorage.getItem(props.userid));

    let sid = userData.session_id;
    let uid = userData.userid;

    const res = await fetch(`${api}/start-job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: id,
        unique_string: uid,
        session_id: sid,
        number_of_people: noOfPerson,
        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setIsloading(false);
      setError(data.message);
      setOpen(true);
    } else if (res.status === 200) {
      setIsloading(false);
      props.clicked();

      let userData = JSON.parse(await localStorage.getItem(props.userid));

      let userData1 = {
        record_id: data.record_id,
        job_id: id,
        number_of_people: noOfPerson,
        selected_Job: selectedJob,
      };

      let z = Object.assign(userData, userData1);
      const jsonValue = JSON.stringify(z);
      await localStorage.setItem(props.userid, jsonValue);

      props.modalOff();
      setError("");
    }
  };
  React.useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  React.useEffect(() => {
    let result;
    let counters1 = 0;
    result = v !== undefined && v.map((e) => e.is_active);

    for (let i = 0; i < result.length; i++) {
      if (result[i] === true) {
        setRecord(false);
        counters1 = counters1 + 1;
      }
    }

    setcounters({
      active: counters1,
      all: result.length,
    });
  }, [v]);

  useImperativeHandle(ref, () => ({
    noofpersion() {
      setNoOfPerson(0);
    },
  }));

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* {!props.allList ? "Today's Job" : "All Jobs"} */}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 310 }}>
          {v === undefined ? (
            <div className="spinner">
              <div className="spinner-border text-primary " role="status"></div>
            </div>
          ) : null}
          <span
            className={
              record && !props.allList && v !== undefined ? "mb-5" : "d-none"
            }
          >
            No Jobs Available
          </span>

          {v !== undefined &&
            v.map((e) => (
              <div
                className={
                  !props.allList && e.is_active && !props.isNextClick
                    ? "p-0"
                    : "d-none" && props.allList && !props.isNextClick
                    ? "p-0"
                    : "d-none"
                }
              >
                <Ripples style={{ background_color: "red" }}>
                  <div className="input-list">
                    <div className="form-check" key={e.id}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radioList"
                        id={e.id}
                        // checked={
                        //   e.id ===  id
                        //     ? !props.isRedioChecked
                        //     : props.isRedioChecked
                        // }
                        value={e.job_name}
                        onChange={handleChange}
                      />
                      {/* //*in here we have to change index to e.id when proper result comes */}
                      <label className="form-check-label" htmlFor={e.id}>
                        {e.job_name}
                      </label>
                    </div>
                  </div>
                </Ripples>
              </div>
            ))}

          <div className={props.isNextClick ? "" : "d-none"}>
            <h2 class="mb-4 mt-3"> No. of people cleaning this site.</h2>
            <div className="row mb-5">
              <Button
              
                className="col-2 btn btn-primary btn-lg ml-2 btnwidth  "
                onClick={() => setNoOfPerson(1)}
              >
                1
              </Button>

              <Button
                className="col-2 btn btn-primary btn-lg mb-2 btnwidth "
                onClick={() => setNoOfPerson(2)}
              >
                2
              </Button>
              <Button
                className="col-2 btn btn-primary btn-lg btnwidth "
                onClick={() => setNoOfPerson(3)}
              >
                3
              </Button>
              <Button
                className="col-2 btn btn-primary btn-lg mb-2 btnwidth "
                onClick={() => setNoOfPerson(4)}
              >
                4
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ marginRight: "auto" }}>
            {!props.allList ? (
              <Button
                className={
                  props.isNextClick
                    ? "d-none"
                    : "btn btn-primary square btn-lg me-1"
                }
                onClick={props.showAllJobList}
              >
                Show All Jobs
              </Button>
            ) : (
              <Button
                className={
                  props.isNextClick
                    ? "d-none"
                    : "btn btn-primary square btn-lg me-1"
                }
                onClick={props.showtodayJobList}
              >
                Show Today's Jobs
              </Button>
            )}

            <Button
              className={
                !props.isNextClick
                  ? "d-none"
                  : "btn btn-primary square btn-lg me-1"
              }
              onClick={props.showtodayJobList}
            >
              Back
            </Button>
          </div>
          {!props.isNextClick ? (
            <Button
              className="btn btn-primary square btn-lg me-1 d-none"
              onClick={props.nextbtnmodal}
            >
              Next
            </Button>
          ) : (
            <Button
              className="btn btn-primary square btn-lg me-1"
              onClick={startJobapi}
              disabled={isloading || noOfPerson === 0 ? "disabled" : null}
            >
              {!isloading ? (
                "Start Job"
              ) : (
                <div className="">
                  <span
                    className="spinner-border spinner-border-sm me-05"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Start Job
                </div>
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
});

export default StartBtnModal;
