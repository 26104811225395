import React from "react";
import Home from "./components/Home";
import MessageList from "./components/MessageList";
import MessageDetails from "./components/MessageDetails";
import JobList from "./components/JobList";
import JobDetails from "./components/JobDetails";
import Login from "./components/Login";
import Setup from "./components/Setup";

import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/Setup/:id" component={Setup} />
        <Route exact path="/Home/:id" component={Home} />
        <Route exact path="/MessageList/:id" component={MessageList} />
        <Route
          exact
          path="/MessageDetails/:id/:selectedId"
          component={MessageDetails}
        />
        <Route exact path="/JobList/:id" component={JobList} />
        <Route
          exact
          path="/JobDetails/:id/:selectedId"
          component={JobDetails}
        />
      </Switch>
    </div>
  );
}

export default App;
