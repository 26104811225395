import React from "react";
import Header from "./Header";
import BottomNav from "./BottomNav";
import { useHistory } from "react-router-dom";
import Ripples from 'react-ripples'
import { api } from "./const";
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
function JobList() {
  const { id } = useParams();
  var [jobList, setJobList] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [loder, setLoder] = React.useState(false);
  const [showloadMore, setShowloadMore] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [isloading, setIsloading] = React.useState(false);
  const temparary = () => {
    setIsloading(true);

    grtJobList();
  };
  // eslint-disable-next-line
  React.useEffect(() => grtJobList(), []);

  const grtJobList = async () => {
    setLoder(true);
    let userData = JSON.parse(await localStorage.getItem(id));
    let sid = userData.session_id;
    let uid = userData.userid;

    const res = await fetch(`${api}/list-all-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search: search,
        page: page,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: "4.23.255.558",
          longitude: "4.23.255.558",
        },
        // location_info: {
        //   latitude: Location.latitude,
        //   longitude: Location.longitude,
        // },
      }),
    });
    const data = await res.json();
    setIsloading(false);
    if (!data || res.status === 400) {
      setLoder(false);
    } else if (res.status === 200) {
      setPage(page + 1);
      setLoder(false);
      if (data.result.length === 0) {
        setShowloadMore(false);
      } else {
        let arr = jobList.concat(data.result);

        setJobList(arr);
      }
    }
  };

  const serchResult = (event) => {
    setPage(1)
    if (event.key === "Enter") {
      event.preventDefault();

      setJobList([]);
    
      if (search !== "") {
        grtJobList();
      }

      console.log(search);
    }
  };
  const history=useHistory();
  const delayed=(id1) => {
    console.log("i am console");
      setTimeout(() => {
        history.push(`/JobDetails/${id}/${id1}`)
      }, 200);
    }
  return (
    <div className="App">
      <Header name="Jobs" />
      <div className="extraHeader">
        <form className="search-form">
          <div className="form-group searchbox">
            <input
              type="text"
              className="form-control"
              placeholder="Search Jobs"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={serchResult}
            />
            <i className="input-icon icon ion-ios-search"></i>
          </div>
        </form>
      </div>
      <div className="main-container">
        <ul className="listview image-listview media mb-2">
          {loder ? (
            <div className="spinner1">
              <div className="spinner-border text-primary " role="status"></div>
            </div>
          ) : null}

          {jobList !== undefined &&
            jobList.map((e) => (
              <li key={e.id}>
                <Ripples     
                onClick={()=>delayed(e.id)} className="item">
                  <div className="imageWrapper">
                    <div className="circle">{e.initial_char}</div>
                  </div>
                  <div className="in width-joblist">
                    <div className="text-truncate">
                      {e.job_name}
                      <div className="text-muted">{e.job_sub_text}</div>
                    </div>
                  </div>
                </Ripples>
              </li>
            ))}

          <div
            className={
              jobList.length === 0 ? "d-none" : "d-flex justify-content-center"
            }
          >
            <Button
              type="button"
              className={
                showloadMore
                  ? "btn btn-primary square btn-lg me-2 mt-2 "
                  : "d-none"
              }
              onClick={temparary}
            >
              {isloading ? (
                <span
                  className="spinner-border spinner-border-sm me-05"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              Load More...
            </Button>
          </div>
        </ul>
      </div>
      <BottomNav userid={id} />
    </div>
  );

  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       flexDirection: "column",
  //       minHeight: "100vh",
  //     }}
  //   >
  //     <Header name="Jobs" />
  //     <h2>Coming Soon...</h2>
  //     <h4 className="text-center">
  //       Job details (site address, alarm codes etc.)
  //       <br />
  //       will be displayed here.
  //     </h4>
  //     <BottomNav userid={id} />
  //   </div>
  // );
}

export default JobList;
