import React from "react";
import { Modal } from "react-bootstrap";
import { api } from "./const";
import Button from '@material-ui/core/Button';
function SupplyModal(props) {
  const supplyResult = props.supplyData.result;
  const [supplyItems, setSupplyItems] = React.useState([]);
  const [Location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
  });
  const [isloading, setIsloading] = React.useState(false);
  React.useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    var items = [];

    if (typeof supplyResult !== "undefined") {
      // eslint-disable-next-line
      supplyResult.map((item) => {
        items[item.id] = item.is_checked;
      });
      setSupplyItems(items);
    }
  }, [supplyResult]);

  // const [arr, setArr] = React.useState([]);

  const handleChange = (event) => {
    var target = event.target;
    var value = parseInt(target.value);

    // if (target.checked) {
    //   arr[value] = value;
    // } else {
    //   arr.splice(value, 1);
    // }

    supplyItems[value] = !supplyItems[value];
    setSupplyItems({ ...supplyItems });
  };

  const submitSupplyApi = async () => {
    setIsloading(true);

    let userData = JSON.parse(await localStorage.getItem(props.userid));
    let sid = userData.session_id;
    let uid = userData.userid;

    let jid = userData.job_id;

    console.log(supplyItems);
    let Newarr = [];

    for (let t in supplyItems) {
      if (supplyItems[t] === true) {
        Newarr.push(t);
      }
    }

    const res = await fetch(`${api}/save-job-supply`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: jid,
        ids: Newarr,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      console.log(data);
      setIsloading(false);
    } else if (res.status === 200) {
      console.log(data);
      setIsloading(false);

      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Select supplies required.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 310, overflow: "scroll" }}>
        {supplyResult === undefined ? (
          <div className="spinner">
            <div className="spinner-border text-primary " role="status"></div>
          </div>
        ) : null}

        <span
          className={
            supplyResult !== undefined && supplyResult.length === 0
              ? "mb-5"
              : "d-none"
          }
        >
          No Supply Available
        </span>

        {supplyResult !== undefined &&
          supplyResult.map((e) => (
            <div className="p-0" key={e.id}>
              <div className="input-list" style={{ marginLeft: -12 }}>
                <div className="form-check" key={e.id}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={e.id}
                    checked={supplyItems[e.id]}
                    value={e.id}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor={e.id}>
                    {e.display_text}
                  </label>
                </div>
              </div>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        {!isloading ? (
          <Button
            type="button"
            className="btn btn-primary square btn-lg me-1"
            onClick={submitSupplyApi}
          >
            Submit
          </Button>
        ) : (
          <Button
            type="button"
            className="btn btn-primary square btn-lg me-1"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm me-05"
              role="status"
              aria-hidden="true"
            ></span>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default SupplyModal;
