import React from "react";
import { NavLink } from "react-router-dom";
import pdf from "../icons/pdf.svg";
// import doc from "../icons/doc.svg";
// import video from "../icons/video.svg";
// import xls from "../icons/xls.svg";
import "../Message.css";
import Zoom from "react-medium-image-zoom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import "react-medium-image-zoom/dist/styles.css";
import BottomNav from "./BottomNav";
import { api } from "./const";
import { useParams } from "react-router-dom";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function JobDetails() {
  let { selectedId, id } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [error, setError] = React.useState("");
  const handleClick = () => {
    setOpen(true);
  };
  const download = (url) => {
    handleClick();
    window.open({ url }, "_blank");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [JobDetailList, setJobDetailList] = React.useState({});
  // eslint-disable-next-line
  React.useEffect(() => getJobDetailList(), []);

  const getJobDetailList = async () => {
    let userData = JSON.parse(await localStorage.getItem(id));
    let sid = userData.session_id;
    let uid = userData.userid;

    const res = await fetch(`${api}/job-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedId,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: "4.23.255.558",
          longitude: "4.23.255.558",
        },
        // location_info: {
        //   latitude: Location.latitude,
        //   longitude: Location.longitude,
        // },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setError(data.message);
      setOpen(true);
    } else if (res.status === 200) {
      setJobDetailList(data);
      setError("");
    }
  };

  return (
    <div>
      <div className="appHeader">
        <div className="left">
          <NavLink to={`/JobList/${id}`} className="headerButton goBack">
            <div className="back-icon"></div>
          </NavLink>
        </div>
        <div className="pageTitle">Jobs</div>
        <div className="right"></div>
      </div>
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        <div>
          <div className=" mt-2">
            {error === "" && JobDetailList.result === undefined ? (
              <div className="spinner1" style={{ marginTop: 80 }}>
                <div
                  className="spinner-border text-primary "
                  role="status"
                ></div>
              </div>
            ) : null}
            <div className="card">
              <div
                className={
                  JobDetailList.result === undefined ? "d-none" : "card-body"
                }
              >
                <h3 className="">Job</h3>
                {JobDetailList.result !== undefined &&
                  JobDetailList.result.map((e) => (
                    <div>
                      <div className="d-flex align-center mt-2">
                        <NavLink to="#" className="headerButton pl-0">
                          <ion-icon name="add-circle"></ion-icon>
                        </NavLink>
                        <h3 className="px-3">{e.title}</h3>
                      </div>
                      <div className="section mt-1">
                        <div className="px-3">
                          {e.content}
                          <h3
                            className={
                              e.image_urls.length === 0 ? "d-none" : "mt-2"
                            }
                          >
                            Photos
                          </h3>
                          <div className=" mt-1">
                            <div className="">
                              <div className="mb-1 d-flex overflow-auto ">
                                {e.image_urls !== undefined &&
                                  e.image_urls.map((img1) => (
                                    <Zoom zoomMargin={20}>
                                      <img
                                        src={img1}
                                        alt="abc"
                                        className="imaged w100 px-1"
                                      />
                                    </Zoom>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <h3
                            className={
                              e.attachments.length === 0 ? "d-none" : ""
                            }
                          >
                            Attachments
                          </h3>
                          <div className=" mt-3 d-flex overflow-auto">
                            {e.attachments !== undefined &&
                              e.attachments.map((e1) => (
                                <div className=" mb-2 attachment">
                                  <div>
                                    <img
                                      // src={massageDetailList.attachments.type}
                                      src={pdf}
                                      alt="icon"
                                      height="64"
                                      width="64"
                                      onClick={() => download(e1.url)}
                                      className="mt-1"
                                    />
                                    <div className="text mt-1 px-2">
                                      <h4 className="title">{e1.title}</h4>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.root}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            {error !== "" ? (
              <Alert onClose={handleClose} severity="error">
                {error}
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="success">
                PDF download successful.
              </Alert>
            )}
          </Snackbar>
        </div>
      </div>
      <BottomNav userid={id} />
    </div>
  );
}

export default JobDetails;
