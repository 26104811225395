import React from "react";
import Header from "./Header";
import { api } from "./const";
import "./../Message.css";
import { useHistory } from "react-router-dom";
import Read from "../icons/read-message.svg";
import Unread from "../icons/unread-message.svg";
import BottomNav from "./BottomNav";
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Ripples from 'react-ripples'
function MessageList() {
  const { id } = useParams();
  var [massageList, setMassageList] = React.useState([]);
  const [showloadMore, setShowloadMore] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [loder, setLoder] = React.useState(false);
  const [Location, setLocation] = React.useState({
    latitude: "222.88.44",
    longitude: "222.88.44",
  });

  React.useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, [Location]);
  const [page, setPage] = React.useState(1);
  const [isloading, setIsloading] = React.useState(false);
  const temparary = () => {
    setIsloading(true);

    getMessageList();
  };

  // eslint-disable-next-line
  React.useEffect(() => getMessageList(), []);

  const getMessageList = async () => {
    setLoder(true);
    let userData = JSON.parse(await localStorage.getItem(id));
    let sid = userData.session_id;
    let uid = userData.userid;

    const res = await fetch(`${api}/list-all-messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: page,
        unique_string: uid,
        session_id: sid,
        search: search,
        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setIsloading(false);
      setLoder(false);
      console.log(data);
    } else if (res.status === 200) {
      setPage(page + 1);
      setIsloading(false);
      setLoder(false);
      if (data.result.length === 0) {
        setShowloadMore(false);
      } else {
        let arr = massageList.concat(data.result);

        setMassageList(arr);
      }
    }
  };

  const serchResult = (event) => {
    setPage(1)
    if (event.key === "Enter") {
      event.preventDefault();

      setMassageList([]);
      
      if (search !== "") {
        getMessageList();
      }
    }
  };
const history=useHistory();
  const delayed=(id1) => {
    console.log("i am console");
      setTimeout(() => {
        history.push(`/MessageDetails/${id}/${id1}`)
      }, 200);
    }
  
  return (
    <div className="App">
      <Header name="Messages" />
      <div className="extraHeader">
        <form className="search-form">
          <div className="form-group searchbox">
            <input
              type="text"
              className="form-control"
              placeholder="Search Messages"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={serchResult}
            />
            <i className="input-icon icon ion-ios-search"></i>
          </div>
        </form>
      </div>

      <div className="main-container">
        <ul className="listview image-listview media mb-2">
          {loder ? (
            <div className="spinner1">
              <div className="spinner-border text-primary " role="status"></div>
            </div>
          ) : null}

          {massageList !== undefined &&
            massageList.map((e) => (
      
              <li key={e.id}>
             
                <Ripples
                
                onClick={()=>delayed(e.id)}
                  className={e.is_read ? "item" : "item color"}
                >
               
                  <div className="imageWrapper">
                    <img
                      src={e.is_read ? Read : Unread}
                      alt="icon"
                      height="36"
                      width="36"
                    />
                  </div>
                  <div className="in width-messagelist">
                    <div className="text-truncate">
                      {e.message_title}
                      <div className="text-muted-dark">{e.message_subject}</div>
                      <div className="text-muted">
                        {e.message_date_time_formated}
                      </div>
                    </div>
                
                  </div>
       
                </Ripples>
            
              </li>
         
            ))}
         
          <div
            className={
              massageList.length === 0
                ? "d-none"
                : "d-flex justify-content-center"
            }
          >
            <Button 

              type="button"
              className={
                showloadMore
                  ? "btn btn-primary square btn-lg me-2 mt-2 "
                  : "d-none"
              }
              onClick={temparary}
            >
              {isloading ? (
                <span
                  className="spinner-border spinner-border-sm me-05"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              Load More...
            </Button>
          </div>
        </ul>
      </div>

      <BottomNav userid={id} />
    </div>
  );

  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       flexDirection: "column",
  //       minHeight: "100vh",
  //     }}
  //   >
  //     <Header name="Messages" />
  //     <h2>Coming Soon...</h2>
  //     <h4 className="text-center">Messages will be displayed here.</h4>
  //     <BottomNav userid={id} />
  //   </div>
  // );
}

export default MessageList;
