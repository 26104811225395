import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { api } from "./const";
import "../App.css";
import { browserName, fullBrowserVersion, osName } from "react-device-detect";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Login() {
  const classes = useStyles();
  const [isloading, setIsloading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [Location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
  });

  const [error, setError] = React.useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);

    // window.location.reload();
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const [isChecked, setIsChecked] = React.useState(false);
  const [loginCode, setLoginCode] = React.useState("");
  // eslint-disable-next-line
  const history = useHistory();

  const userLogin = async (e) => {
    setIsloading(true);
    // clearUser();

    e.preventDefault();

    const res = await fetch(`${api}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        login_code: loginCode,
        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
        browser_info: {
          name: browserName,
          version: fullBrowserVersion,
          device_type: osName,
          other_info: "is now",
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setIsloading(false);
      setError(data.message);
      setOpen(true);
    } else if (res.status === 200) {
      let userData = {
        userid: data.unique_string,
        session_id: data.session_id,
        userName: data.user_info.full_name,
      };
      const jsonValue = JSON.stringify(userData);
      await localStorage.setItem(data.unique_string, jsonValue);
      setIsloading(false);
      window.location.href = `/Home/${data.unique_string}`;
      // history.push(`/Home/${data.unique_string}`);
    }
  };
  // const clearUser = async () => {
  //   await localStorage.removeItem("MyInfo");
  // };

  React.useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  return (
    <div>
      <div id="appCapsule">
        <div className="section mb-5 p-2 center1">
          <h1 className="mb-3" style={{ textAlign: "center" }}>
            App Setup
          </h1>
          <form>
            <div className="card">
              <div className="card-body pb-1">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <input
                      style={{ fontSize: 20 }}
                      type="text"
                      className="form-control"
                      id="text"
                      placeholder="Enter your setup code"
                      value={loginCode}
                      onChange={(e) => setLoginCode(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-check mb-1 mt-1 ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckb1"
                    checked={isChecked}
                    onChange={handleOnChange}
                  />
                  <label className="form-check-label" htmlFor="customCheckb1">
                    I agree To{" "}
                    <span className="terms">
                      <NavLink target="_blank" to="/terms-of-use.html">
                        terms of use
                      </NavLink>
                    </span>.
                  </label>
                </div>
              </div>
            </div>
          </form>

          <div className="form-button-group transparent">
            {!isloading ? (
              <Button
                type="submit"
                className="btn btn-primary btn-block btn-lg square"
                disabled={!isChecked ? "disabled" : null}
                onClick={userLogin}
              >
                Next
              </Button>
            ) : (
              <Button
                type="button"
                disabled
                className="btn btn-primary btn-block btn-lg square"
              >
                <span
                  className="spinner-border spinner-border-sm me-05"
                  role="status"
                  aria-hidden="true"
                ></span>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Login;
