import React, { forwardRef, useImperativeHandle } from "react";
import { api } from "./const";
import { NavLink } from "react-router-dom";
import "../App.css";

const BottomNav = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getMessageList,
  }));

  const [id, setId] = React.useState("");
  const [unRead, setUnRead] = React.useState(0);
  const getUserId = async () => {
    let userData = JSON.parse(localStorage.getItem(props.userid));

    setId(userData.userid);
  };

  React.useEffect(() => {
    getUserId();
    // eslint-disable-next-line
  }, [id]);
  // eslint-disable-next-line
  React.useEffect(() => getMessageList(), []);

  const getMessageList = async () => {
    let userData = JSON.parse(await localStorage.getItem(props.userid));
    let sid = userData.session_id;
    let uid = userData.userid;

    const res = await fetch(`${api}/list-all-messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // page: page,
        only_count: 1,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: "4.23.255.558",
          longitude: "4.23.255.558",
        },
        // location_info: {
        //   latitude: Location.latitude,
        //   longitude: Location.longitude,
        // },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
    } else if (res.status === 200) {
      setUnRead(data.unread_count);
    }
  };

  return (
    <div className="appBottomMenu">
      {/* //* home svg.................. */}
      <NavLink to={`/Home/${id}`} className="item">
        <div className="col">
          <svg
            className="ion-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M13 13v8h8v-8h-8zM3 21h8v-8H3v8zM3 3v8h8V3H3zm13.66-1.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65z"></path>
          </svg>

          <strong>Home</strong>
        </div>
      </NavLink>
      {/* //* message svg............................ */}
      <NavLink to={`/MessageList/${id}`} className="item">
        <div className="col">
          <svg
            className="ion-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="1.4em"
            height="1.4em"
            fill="currentColor"
            viewBox="0 0 50 50"
          >
            <path d="M0 7L0 9.78125C3.011719 12.402344 20.289063 27.464844 21.65625 28.65625C23.0625 29.882813 24.464844 30 25 30C25.535156 30 26.9375 29.882813 28.34375 28.65625C29.761719 27.421875 48.046875 11.480469 50 9.78125L50 7 Z M 0 12.4375L0 37.53125L15.125 25.59375C10.222656 21.328125 3.230469 15.25 0 12.4375 Z M 50 12.4375C46.949219 15.09375 39.847656 21.265625 34.875 25.59375L50 37.5 Z M 16.65625 26.9375L0 40.0625L0 43L50 43L50 40.03125L33.34375 26.9375C31.40625 28.625 30.011719 29.847656 29.65625 30.15625C27.738281 31.824219 25.757813 32 25 32C24.242188 32 22.261719 31.828125 20.34375 30.15625C19.992188 29.851563 18.597656 28.628906 16.65625 26.9375Z" />
          </svg>

          <span
            className={unRead === 0 ? "d-none" : "badge-footer-menu blinker"}
          >
            {unRead}
          </span>
          <strong>Messages</strong>
        </div>
      </NavLink>
      {/* //* Jobs svg....................... */}
      <NavLink to={`/jobList/${id}`} className="item">
        <div className="col">
          <svg
            className="ion-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            fill="currentColor"
            viewBox="0 0 50 50"
          >
            <path d="M5 4C4.449219 4 4 4.449219 4 5L4 45C4 45.550781 4.449219 46 5 46L45 46C45.550781 46 46 45.550781 46 45L46 5C46 4.449219 45.550781 4 45 4 Z M 14 12C15.105469 12 16 12.894531 16 14C16 15.105469 15.105469 16 14 16C12.894531 16 12 15.105469 12 14C12 12.894531 12.894531 12 14 12 Z M 21 13L38 13C38.550781 13 39 13.445313 39 14C39 14.554688 38.550781 15 38 15L21 15C20.449219 15 20 14.554688 20 14C20 13.445313 20.449219 13 21 13 Z M 14 23C15.105469 23 16 23.894531 16 25C16 26.105469 15.105469 27 14 27C12.894531 27 12 26.105469 12 25C12 23.894531 12.894531 23 14 23 Z M 21 24L38 24C38.550781 24 39 24.445313 39 25C39 25.554688 38.550781 26 38 26L21 26C20.449219 26 20 25.554688 20 25C20 24.445313 20.449219 24 21 24 Z M 14 34C15.105469 34 16 34.894531 16 36C16 37.105469 15.105469 38 14 38C12.894531 38 12 37.105469 12 36C12 34.894531 12.894531 34 14 34 Z M 21 35L38 35C38.550781 35 39 35.445313 39 36C39 36.554688 38.550781 37 38 37L21 37C20.449219 37 20 36.554688 20 36C20 35.445313 20.449219 35 21 35Z"></path>
          </svg>

          <strong>Jobs</strong>
        </div>
      </NavLink>
    </div>
  );
});

export default BottomNav;
