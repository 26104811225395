import React from "react";
import { NavLink } from "react-router-dom";
import pdf from "../icons/pdf.svg";
// import doc from "../icons/doc.svg";
// import video from "../icons/video.svg";
// import xls from "../icons/xls.svg";
import "../Message.css";
import Zoom from "react-medium-image-zoom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Form } from "react-bootstrap";
import BottomNav from "./BottomNav";
import "react-medium-image-zoom/dist/styles.css";
import { api } from "./const";
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
function MyVerticallyCenteredModal(props) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [isloading, setIsloading] = React.useState(false);
  let { selectedId, id } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [error, setError] = React.useState("");
  const markasreadApi = async () => {
    setIsloading(true);
    let userData = JSON.parse(await localStorage.getItem(id));
    let sid = userData.session_id;
    let uid = userData.userid;

    const res = await fetch(`${api}/mark-message-as-read`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedId,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: "4.23.255.558",
          longitude: "4.23.255.558",
        },
        // location_info: {
        //   latitude: Location.latitude,
        //   longitude: Location.longitude,
        // },
      }),
    });
    const data = await res.json();
    setIsloading(false);
    if (!data || res.status === 400) {
      setError(data.message);
      setOpen(true);
    } else if (res.status === 200) {
      props.onHide();
      setOpen(true);
      props.mymetod();
    }
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const [isChecked, setIsChecked] = React.useState(false);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Have you read and understood the message? If not, contact your
            supervisor.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Form
            style={{
              marginRight: "auto",
              marginTop: 20,
            }}
          >
            <Form.Group className="mb-3 my-auto" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Yes, Mark it as read."
                checked={isChecked}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Form>

          {!isloading ? (
            <Button 
              type="button"
              className="btn btn-primary square btn-lg me-1"
              disabled={!isChecked ? "disabled" : null}
              onClick={markasreadApi}
            >
              Submit
            </Button>
          ) : (
            <Button
              type="button"
              className="btn btn-primary square btn-lg me-1"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm me-05"
                role="status"
                aria-hidden="true"
              ></span>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {error !== "" ? (
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          ) : (
            <Alert onClose={handleClose} severity="success">
              Message marked as read successfully
            </Alert>
          )}
        </Snackbar>
      </div>
    </>
  );
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function MessageDetails() {
  const childRef = React.useRef();
  let { selectedId, id } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleClick = () => {
    setOpen(true);
  };
  const download = (url) => {
    handleClick();
    window.open({ url }, "_blank");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [massageDetailList, setMassageDetailList] = React.useState({});
  // eslint-disable-next-line
  React.useEffect(() => getMessageDetailList(), []);

  const getMessageDetailList = async () => {
    let userData = JSON.parse(await localStorage.getItem(id));
    let sid = userData.session_id;
    let uid = userData.userid;

    const res = await fetch(`${api}/message-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedId,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: "4.23.255.558",
          longitude: "4.23.255.558",
        },
        // location_info: {
        //   latitude: Location.latitude,
        //   longitude: Location.longitude,
        // },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setError(data.message);
      setOpen(true);
    } else if (res.status === 200) {
      setMassageDetailList(data);
    }
  };

  return (
    <div>
      <div className="appHeader">
        <div className="left">
          <NavLink to={`/MessageList/${id}`} className="headerButton goBack">
            <div className="back-icon"></div>
          </NavLink>
        </div>
        <div className="pageTitle">Messages</div>
        <div className="right"></div>
      </div>
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        <div>
          <div className=" mt-2">
            <div>
              {error === "" && massageDetailList.image_urls === undefined ? (
                <div className="spinner1" style={{ marginTop: 80 }}>
                  <div
                    className="spinner-border text-primary "
                    role="status"
                  ></div>
                </div>
              ) : null}
              <div
                className={
                  massageDetailList.image_urls === undefined
                    ? "d-none"
                    : "card-body"
                }
              >
                <h3>Job: {massageDetailList.job_name}</h3>
                <h3>Subject: {massageDetailList.subject}</h3>
                <div className="blog-header-info mt-2 mb-2">
                  <div>{massageDetailList.date_time_formated}</div>
                </div>
                <div className="section mt-2">
                  <div>{massageDetailList.content}</div>
                </div>
                <div className="my-3 d-flex overflow-auto">
                  {massageDetailList.image_urls !== undefined &&
                    massageDetailList.image_urls.map((e) => (
                      <Zoom zoomMargin={20}>
                        <img src={e} alt="abc" className="imaged w100 px-1" />
                      </Zoom>
                    ))}
                </div>

                <div className="section mt-3">
                  <h3>Attachments</h3>
                  <div className=" mt-3 d-flex owerflow-auto">
                    {massageDetailList.attachments !== undefined &&
                      massageDetailList.attachments.map((e) => (
                        <div className="col-3 mb-2 attachment">
                          <div>
                            <img
                              src={pdf}
                              alt="icon"
                              height="64"
                              width="64"
                              onClick={() => download(e.url)}
                              className="mt-1"
                            />
                            <div className="text mt-1 px-2">
                              <h4 className="title">{e.title}</h4>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="mt-2"></div>
                <Button
                  type="button"
                  className="btn btn-lg bg-color square btn-block "
                  onClick={() => setModalShow(true)}
                  disabled={massageDetailList.is_read ? "disabled" : null}
                >
                  Mark As Read
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            {error !== "" ? (
              <Alert onClose={handleClose} severity="error">
                {error}
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="success">
                PDF download successful.
              </Alert>
            )}
          </Snackbar>
        </div>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          mymetod={() => {
            childRef.current.getMessageList();
          }}
        />
      </div>
      <BottomNav userid={id} ref={childRef} />
    </div>
  );
}

export default MessageDetails;
