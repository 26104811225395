import React, { forwardRef, useImperativeHandle } from "react";
import { api } from "./const";
import { Modal, Form } from "react-bootstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const NotesModal = forwardRef((props, ref) => {
  const [note, setNotes] = React.useState("");

  useImperativeHandle(ref, () => ({
    cleanNote() {
      setNotes("");
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [isloading, setIsloading] = React.useState(false);
  const [Location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
  });

  React.useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => setNotes(props.preNotes), [props.preNotes]);
  // React.useEffect(() => {
  //   let userData = JSON.parse(localStorage.getItem(props.userid));

  //   setNotes(userData.usernotes);
  // }, [props.userid]);

  const NotesApi = async () => {
    setIsloading(true);

    let userData = JSON.parse(await localStorage.getItem(props.userid));
    let sid = userData.session_id;
    let uid = userData.userid;
    let rid = userData.record_id;
    let jid = userData.job_id;
    let nofpersion = userData.number_of_people;

    const res = await fetch(`${api}/notes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: jid,
        number_of_people: nofpersion,
        notes: note,
        record_id: rid,
        unique_string: uid,
        session_id: sid,

        location_info: {
          latitude: Location.latitude,
          longitude: Location.longitude,
        },
      }),
    });
    const data = await res.json();

    if (!data || res.status === 400) {
      setIsloading(false);
      setError(data.message);
      setOpen(true);
    } else if (res.status === 200) {
      setIsloading(false);
      setOpen(true);

      setError("");
      props.NotesModalOff();
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.loder ? (
            <div className="spinner">
              <div className="spinner-border text-primary " role="status"></div>
            </div>
          ) : (
            <Form>
              <Form.Group
                className="mb-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={8}
                  value={note}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isloading ? (
            <Button

              type="button"
              className="btn btn-primary square btn-lg me-1"
              onClick={NotesApi}
            >
              Submit
            </Button>
          ) : (
            <Button
              type="button"
              disabled
              className="btn btn-primary square btn-lg me-1"
            >
              <span
                className="spinner-border spinner-border-sm me-05"
                role="status"
                aria-hidden="true"
              ></span>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {error !== "" ? (
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          ) : (
            <Alert onClose={handleClose} severity="success">
              Notes updated Successfully
            </Alert>
          )}
        </Snackbar>
      </div>
    </>
  );
});

export default NotesModal;
